<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <div class="mt-2">
                <b-card
                  header="Dati Anagrafici"
                  header-tag="header"
                  v-if="form[rep].status_registry === 0"
                >
                  <b-card-text>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_NAME_value"
                          :name="beForm[rep].attribute_NAME_value.label"
                          :label="getDictionary('attribute_NAME_value', rep)"
                          v-model="form[rep].attribute_NAME_value"
                          placeholder="Inserisci un nome"
                          :rules="getRules('attribute_NAME_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_SURN_value"
                          :name="beForm[rep].attribute_SURN_value.label"
                          :label="getDictionary('attribute_SURN_value', rep)"
                          v-model="form[rep].attribute_SURN_value"
                          placeholder="Inserisci un cognome"
                          :rules="getRules('attribute_SURN_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-select
                          vid="attribute_TITL_value"
                          :name="beForm[rep].attribute_TITL_value.label"
                          :label="getDictionary('attribute_TITL_value', rep)"
                          v-model="form[rep].attribute_TITL_value"
                          :options="beForm[rep].attribute_TITL_value.options"
                          placeholder="Inserisci una descrizione"
                          :rules="getRules('attribute_TITL_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-select
                          vid="attribute_SEX_value"
                          :name="beForm[rep].attribute_SEX_value.label"
                          :label="getDictionary('attribute_SEX_value', rep)"
                          v-model="form[rep].attribute_SEX_value"
                          :options="beForm[rep].attribute_SEX_value.options"
                          :rules="getRules('attribute_SEX_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-dynamic-input
                          :isAssistence="roles.includes('admin-assistenza')"
                          vid="attribute_NINO_value"
                          :name="beForm[rep].attribute_NINO_value.label"
                          :label="getDictionary('attribute_NINO_value', rep)"
                          v-model="form[rep].attribute_NINO_value"
                          :rules="getRules('attribute_NINO_value')"
                          type="text"
                          controlType="text"
                          textcase="upper"
                        />
                      </div>
                    </b-row>
                    Nato a:
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-select
                          vid="attribute_NOB_value"
                          :name="beForm[rep].attribute_NOB_value.label"
                          :label="getDictionary('attribute_NOB_value', rep)"
                          v-model="form[rep].attribute_NOB_value"
                          :options="optBornCountries"
                          :taggable="false"
                          :multiple="false"
                          :closeOnSelect="true"
                          @select="onSelectBornCountry"
                        />
                      </div>
                      <div class="form-group col-md-3" v-if="!isBornForeign">
                        <base-select
                          name="born_province"
                          vid="born_province"
                          label="Provincia"
                          v-model="form.born_province"
                          :options="optBornProvinces"
                          :taggable="false"
                          :multiple="false"
                          :closeOnSelect="true"
                          @select="onSelectBornProvince"
                        />
                      </div>
                      <div class="form-group col-md-3" v-if="!isBornForeign">
                        <base-select
                          vid="attribute_POB_value"
                          :name="beForm[rep].attribute_POB_value.label"
                          :label="getDictionary('attribute_POB_value', rep)"
                          v-model="form[rep].attribute_POB_value"
                          :options="optBornCouncils"
                          :taggable="false"
                          :multiple="false"
                          :closeOnSelect="true"
                          :rules="getRules('attribute_POB_value')"
                        />
                      </div>
                      <div class="form-group col-md-3" v-else>
                        <base-input
                          vid="attribute_POB2_value"
                          :name="beForm[rep].attribute_POB2_value.label"
                          :label="getDictionary('attribute_POB2_value', rep)"
                          v-model="form[rep].attribute_POB2_value"
                          :rules="getRules('attribute_POB2_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-datepicker
                          vid="attribute_DOB_value"
                          :name="beForm[rep].attribute_DOB_value.label"
                          :label="getDictionary('attribute_DOB_value', rep)"
                          v-model="form[rep].attribute_DOB_value"
                          :rules="getRules('attribute_DOB_value')"
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="mt-2">
                <b-card
                  header="Dati Identificativi"
                  header-tag="header"
                  v-if="form[rep].status_registry === 1"
                >
                  <b-card-text>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_CNAM_value"
                          :name="beForm[rep].attribute_CNAM_value.label"
                          :label="getDictionary('attribute_CNAM_value', rep)"
                          v-model="form[rep].attribute_CNAM_value"
                          :rules="getRules('attribute_CNAM_value')"
                          placeholder="Inserisci una ragione sociale"
                        />
                      </div>
                      <!-- <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_TREG_value"
                          :name="beForm[rep].attribute_TREG_value.label"
                          :label="getDictionary('attribute_TREG_value', rep)"
                          v-model="form[rep].attribute_TREG_value"
                          :rules="getRules('attribute_TREG_value')"
                        />
                      </div> -->
                      <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_CREG_value"
                          :name="beForm[rep].attribute_CREG_value.label"
                          :label="getDictionary('attribute_CREG_value', rep)"
                          v-model="form[rep].attribute_CREG_value"
                          :rules="getRules('attribute_CREG_value')"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_NINO_value"
                          :name="beForm[rep].attribute_NINO_value.label"
                          :label="getDictionary('attribute_NINO_value', rep)"
                          v-model="form[rep].attribute_NINO_value"
                          :rules="getRules('attribute_NINO_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-select
                          vid="attribute_LIAB_value"
                          :name="beForm[rep].attribute_LIAB_value.label"
                          :label="getDictionary('attribute_LIAB_value', rep)"
                          v-model="form[rep].attribute_LIAB_value"
                          :rules="getRules('attribute_LIAB_value')"
                          :options="beForm[rep].attribute_LIAB_value.options"
                          :taggable="false"
                          :multiple="false"
                          :closeOnSelect="true"
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="mt-1">
                <address-book-form
                  v-model="address_book_form"
                  :beECForm="beForm[addrBook]"
                  :repository="addrBook"
                ></address-book-form>
              </div>
              <div class="mt-2">
                <correspondence-form
                  v-model="correspondence_form"
                  :beECForm="beForm[cor]"
                  :repository="cor"
                >
                </correspondence-form>
              </div>
              <div class="mt-2">
                <b-card
                  v-if="form[rep].status_registry === 1"
                  header="Altri Dati"
                  header-tag="header"
                >
                  <b-card-text>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_PROF_value"
                          :name="beForm[rep].attribute_PROF_value.label"
                          label="Attività"
                          v-model="form[rep].attribute_PROF_value"
                          placeholder="Inserisci il numero di documento"
                          :rules="getRules('attribute_PROF_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-select
                          vid="attribute_CHAN_value"
                          :name="beForm[rep].attribute_CHAN_value.label"
                          label="Canale Preferito"
                          v-model="form[rep].attribute_CHAN_value"
                          :options="beForm[rep].attribute_CHAN_value.options"
                          :rules="getRules('attribute_CHAN_value')"
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="mt-2">
                <b-card
                  v-if="form[rep].status_registry === 0"
                  header="Documento"
                  header-tag="header"
                >
                  <b-card-text>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-radio
                          vid="attribute_DOCT_value"
                          :name="beForm[rep].attribute_DOCT_value.label"
                          :label="getDictionary('attribute_DOCT_value', rep)"
                          v-model="form[rep].attribute_DOCT_value"
                          :options="beForm[rep].attribute_DOCT_value.options"
                          :rules="getRules('attribute_DOCT_value')"
                          stacked
                        />
                      </div>
                    </b-row>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_DOCN_value"
                          :name="beForm[rep].attribute_DOCN_value.label"
                          :label="getDictionary('attribute_DOCN_value', rep)"
                          v-model="form[rep].attribute_DOCN_value"
                          placeholder="Inserisci il numero di documento"
                          :rules="getRules('attribute_DOCN_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-select
                          vid="attribute_ISPL_value"
                          :name="beForm[rep].attribute_ISPL_value.label"
                          :label="getDictionary('attribute_ISPL_value', rep)"
                          v-model="form[rep].attribute_ISPL_value"
                          :options="ajaxOptions"
                          placeholder="Digitare una località"
                          @search-change="getAjaxOptions"
                          :isLoading="isLoadingAjax"
                          :rules="getRules('attribute_ISPL_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-datepicker
                          vid="attribute_DOCS_value"
                          :name="beForm[rep].attribute_DOCS_value.label"
                          :label="getDictionary('attribute_DOCS_value', rep)"
                          v-model="form[rep].attribute_DOCS_value"
                          :rules="getRules('attribute_DOCS_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-datepicker
                          vid="attribute_DOCX_value"
                          :name="beForm[rep].attribute_DOCX_value.label"
                          :label="getDictionary('attribute_DOCX_value', rep)"
                          v-model="form[rep].attribute_DOCX_value"
                          :rules="getRules('attribute_DOCX_value')"
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="mt-2" v-if="form[rep].status_registry === 0">
                <b-card header="Altro" header-tag="header">
                  <b-card-text>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-radio
                          vid="attribute_MARS_value"
                          :name="beForm[rep].attribute_MARS_value.label"
                          :label="getDictionary('attribute_MARS_value', rep)"
                          v-model="form[rep].attribute_MARS_value"
                          :options="beForm[rep].attribute_MARS_value.options"
                          :rules="getRules('attribute_MARS_value')"
                          stacked
                        />
                      </div>
                    </b-row>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-input
                          vid="attribute_PROF_value"
                          :name="beForm[rep].attribute_PROF_value.label"
                          :label="getDictionary('attribute_PROF_value', rep)"
                          v-model="form[rep].attribute_PROF_value"
                          placeholder="Inserisci una professione"
                          :rules="getRules('attribute_PROF_value')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-select
                          vid="attribute_CHAN_value"
                          :name="beForm[rep].attribute_CHAN_value.label"
                          :label="getDictionary('attribute_CHAN_value', rep)"
                          v-model="form[rep].attribute_CHAN_value"
                          :options="beForm[rep].attribute_CHAN_value.options"
                          :rules="getRules('attribute_CHAN_value')"
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <b-row>
                      <custom-inputs
                        v-for="(element, index) in customInputs[rep]"
                        :key="index"
                        :input="element"
                        v-model="form[rep]"
                        :beForm="beForm[rep]"
                        :beRules="beRules[rep]"
                      />
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="mt-1" v-if="canModule('module.PEASY')">
                <data-protection-form
                  v-model="data_protection_form"
                  :beECForm="beForm[dtPrt]"
                  :repository="dtPrt"
                  :rules="{
                    intermediary_consent: getRules(
                      'intermediary_consent',
                      'data_protection'
                    ),
                    traditional_consent: getRules(
                      'traditional_consent',
                      'data_protection'
                    ),
                    electronic_consent: getRules(
                      'electronic_consent',
                      'data_protection'
                    ),
                    thirdparty_consent: getRules(
                      'thirdparty_consent',
                      'data_protection'
                    ),
                  }"
                >
                </data-protection-form>
              </div>
              <div class="mt-2" v-else>
                <b-card header="Privacy - GDPR" header-tag="header">
                  <b-card-text>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-select
                          vid="attribute_GDPR_value"
                          :name="beForm[rep].attribute_GDPR_value.label"
                          :label="getDictionary('attribute_GDPR_value', rep)"
                          v-model="form[rep].attribute_GDPR_value"
                          :options="beForm[rep].attribute_GDPR_value.options"
                          :rules="getRules('attribute_GDPR_value')"
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  v-if="invalid"
                  @click="resetValidationErrors('observer')"
                  variant="lisaweb"
                  size="sm"
                  >Reset errors</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseRadio from "@/components/form/BaseRadio";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDynamicInput from "@/components/form/BaseDynamicInput";
import AddressBookForm from "@/components/form/AddressBookForm";
import CorrespondenceForm from "@/components/form/CorrespondenceForm";
import DataProtectionForm from "@/components/form/DataProtectionForm";
import AddressBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import CustomInputs from "@/components/form/CustomInputs";

export default {
  mixins: [FormMixin, ShortcutMixin, ShowMixin, AddressBookCorrespondenceMixin],
  components: {
    BaseIcon,
    BaseInput,
    BaseRadio,
    AddressBookForm,
    CorrespondenceForm,
    DataProtectionForm,
    BaseSelect,
    BaseDatepicker,
    BaseDynamicInput,
    CustomInputs,
  },
  data() {
    return {
      repository: "registry",
      ajaxOptions: [],
      isLoadingAjax: false,
      id: this.$route.params.id,
      item: this.$route.params.item,
      address_book_id: null,
      correspondence_id: null,
      data_protection_id: null,
      optBornCountries: [],
      optBornProvinces: [],
      optBornCouncils: [],
      form: {
        born_province: null,
        defaultCountryName: "Italia",
        home_province: null,
        optHomeCountries: [],
        optHomeProvinces: [],
        optHomeCouncils: [],
        registry: {
          id: null,
          pk: null,
          sk: null,
          status_registry: null,
          insurer_id: null,
          attribute_NOB_value: null,
          attribute_NINO_value: null,
          attribute_SURN_value: null,
          attribute_NAME_value: null,
          attribute_TITL_value: null,
          attribute_SEX_value: null,
          attribute_DOB_value: null,
          attribute_POB_value: null,
          attribute_POB2_value: null,
          attribute_PEC_value: null,
          attribute_CNAM_value: null,
          attribute_CREG_value: null,
          attribute_LIAB_value: null,
          attribute_TREG_value: null,
          businessDescription: null,
          attribute_DOCT_value: null,
          attribute_ISPL_value: null,
          attribute_DOCS_value: null,
          attribute_DOCX_value: null,
          attribute_MARS_value: null,
          attribute_PROF_value: null,
          attribute_WEB_value: null,
          attribute_CHAN_value: null,
          attribute_GDPR_value: null,
        },
        address_book: {
          status_addressbook: 0,
          attribute_CELL_value: null,
          attribute_FAX_value: null,
          attribute_LPN_value: null,
          attribute_MAIL_value: null,
          attribute_PEC_value: null,
          attribute_PHON_value: null,
          attribute_WEB_value: null,
          registry: {},
        },
        correspondence: {
          status_correspondence: 0,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NOR_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
          registry: {},
        },
        data_protection: {
          registry_id: null,
          intermediary_consent: "N",
          traditional_consent: "N",
          electronic_consent: "N",
          thirdparty_consent: "N",
        },
      },
    };
  },
  beforeMount() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      console.log("BeForm: ", this.beForm);
      let promises = [];
      promises.push(this.fetchAddressBook());
      promises.push(this.fetchCorrespondences());
      if (this.canModule("module.PEASY")) {
        promises.push(this.fetchDataProtection());
      }
      Promise.all(promises)
        .then(() => {
          this.setDefaultValues();
          this.isLoading = false;
        })
        .catch((error) => {
          this.onFailure(error);
        });
    });
  },
  methods: {
    setDefaultValues() {
      // NOB
      if (
        !this.form[this.rep].attribute_NOB_value
        // && !this.form[this.rep].attribute_NOB2_value
      ) {
        // imposta italia
        this.form[this.rep].attribute_NOB_value = this.countries().find(
          (c) => c.text == this.form.defaultCountryName
        ).value;
      }
      //Born Options
      this.optBornCountries = this.countries();
      this.optBornProvinces = this.provinces()(
        this.form[this.rep].attribute_NOB_value
      );
      let bornCouncil;
      if (this.form[this.rep].attribute_POB_value) {
        bornCouncil = this.councils()().find(
          (council) => council.value == this.form[this.rep].attribute_POB_value
        );

        if (bornCouncil) {
          this.$set(this, "optBornCouncils", [
            { value: bornCouncil.value, text: bornCouncil.text },
          ]);
          // this.optBornCouncils = [
          //   { value: bornCouncil.value, text: bornCouncil.text },
          // ];
          // this.form.born_province = bornCouncil.province;
          this.$set(this.form, "born_province", bornCouncil.province);
        } else {
          // non l'ha trovato perché is_dismissed = "Y"...
          // ma lo devo pur far vedere...
          bornCouncil = this.councils()(null, null, true).find(
            (e) => e.value == this.form[this.rep].attribute_POB_value
          );
          this.$set(this, "optBornCouncils", [
            { value: bornCouncil.value, text: bornCouncil.text },
          ]);
          this.$set(this.form, "born_province", bornCouncil.province);
        }
      }
      // if (
      //   this.form[this.rep].status_registry === 0 &&
      //   !this.form[this.rep].attribute_POB2_value
      // ) {
      //   this.form.born_province = this.provinceByCouncil()(
      //     this.form[this.rep].attribute_POB_value,
      //     "id"
      //   );
      //   this.form.born_country = this.countryByProvince()(
      //     this.form.born_province,
      //     "id"
      //   );
      //   this.optBornCouncils = this.councils()(this.form.born_province);
      // }
      //AJAX  Options
      this.ajaxOptions = this.form[this.rep].attribute_ISPL_value
        ? [
            this.councils()().find(
              (el) => el.value == this.form[this.rep].attribute_ISPL_value
            ),
          ] || []
        : [];
    },
    getAjaxOptions(query) {
      if (query.length >= 2) {
        this.isLoadingAjax = true;
        this.ajaxOptions = this.councils()(null, query);
        this.isLoadingAjax = false;
      }
    },
    onSubmit() {
      this.isLoading = true;
      this.clearFormRegistry();
      this.update(this.repository, this.id)
        .then(() => {
          let promises = [];
          if (this.address_book_id) {
            promises.push(
              this.editSection(
                this.addrBook,
                this.address_book_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.addrBook, this.id, this.repository)
            );
          }
          if (this.correspondence_id) {
            promises.push(
              this.editSection(
                this.cor,
                this.correspondence_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.cor, this.id, this.repository)
            );
          }
          if (this.data_protection_id) {
            promises.push(
              this.editSection(
                this.dtPrt,
                this.data_protection_id
                // this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.dtPrt, this.id /* , this.repository */)
            );
          }
          Promise.all(promises)
            .then(() => {
              this.onSucess();
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    clearFormRegistry() {
      this.$delete(this.form[this.cor], "agency_setup");
      this.$delete(this.form[this.cor], "claim");
      this.$delete(this.form[this.cor], "broker");
    },
    onSucess() {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Anagrafica Modificata`,
      });
      console.debug("onSuccess");
      console.debug(this.$route);
      if (this.$route.params.fromTab) {
        this.shortcut(
          "module.PFOLIO",
          null,
          "#Registries",
          "filterRegistry",
          { id: this.id },
          {
            click: "openModal",
            id: this.id,
            modalName: "modal",
            hash: `#${this.$route.params.fromTab}`,
          }
        );
      } else {
        this.shortcut("module.PFOLIO", null, "#Registries", "filterRegistry");
      }
      this.isLoading = false;
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    onSelectBornCountry(selected) {
      this.optBornProvinces = this.provinces()(selected);
      this.optBornCouncils = [];
      this.form[this.rep].attribute_POB2_value = null;
      this.form.born_province = null;
      this.form[this.rep].attribute_POB_value = null;
    },
    onSelectBornProvince(selected) {
      this.optBornCouncils = [];
      this.form[this.repository].attribute_POB_value = null;
      this.optBornCouncils = this.councils()(selected);
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "councils",
      "provinceByCouncil",
      "countryByProvince",
    ]),
  },
  computed: {
    ...mapGetters("auth", ["roles"]),
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
    dtPrt() {
      return "data_protection";
    },
    isBornForeign() {
      if (!this.form[this.rep].attribute_NOB_value) return false;
      return (
        this.optBornCountries.find(
          (c) => c.value == this.form[this.rep].attribute_NOB_value
        ).text !== this.form.defaultCountryName
      );
    },
  },
};
</script>
