var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-card",
        {
          staticClass: "mt-1",
          attrs: { header: "Privacy - PEASY", "header-tag": "header" },
        },
        [
          _c(
            "b-card-text",
            [
              _c("b-row", { staticClass: "mx-1" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-md-6" },
                  [
                    _c("base-select", {
                      attrs: {
                        vid: "intermediary_consent",
                        name: _vm.beECForm.intermediary_consent.label,
                        label: _vm.beECForm.intermediary_consent.label,
                        options: _vm.beECForm.intermediary_consent.options,
                        taggable: false,
                        multiple: false,
                        closeOnSelect: true,
                        rules: _vm.rules.intermediary_consent,
                      },
                      model: {
                        value: _vm.inputVal.intermediary_consent,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "intermediary_consent", $$v)
                        },
                        expression: "inputVal.intermediary_consent",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group col-md-6" },
                  [
                    _c("base-select", {
                      attrs: {
                        vid: "traditional_consent",
                        name: _vm.beECForm.traditional_consent.label,
                        label: _vm.beECForm.traditional_consent.label,
                        options: _vm.beECForm.traditional_consent.options,
                        taggable: false,
                        multiple: false,
                        closeOnSelect: true,
                        rules: _vm.rules.traditional_consent,
                      },
                      model: {
                        value: _vm.inputVal.traditional_consent,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "traditional_consent", $$v)
                        },
                        expression: "inputVal.traditional_consent",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group col-md-6" },
                  [
                    _c("base-select", {
                      attrs: {
                        vid: "electronic_consent",
                        name: _vm.beECForm.electronic_consent.label,
                        label: _vm.beECForm.electronic_consent.label,
                        options: _vm.beECForm.electronic_consent.options,
                        taggable: false,
                        multiple: false,
                        closeOnSelect: true,
                        rules: _vm.rules.electronic_consent,
                      },
                      model: {
                        value: _vm.inputVal.electronic_consent,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "electronic_consent", $$v)
                        },
                        expression: "inputVal.electronic_consent",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group col-md-6" },
                  [
                    _c("base-select", {
                      attrs: {
                        vid: "thirdparty_consent",
                        name: _vm.beECForm.thirdparty_consent.label,
                        label: _vm.beECForm.thirdparty_consent.label,
                        options: _vm.beECForm.thirdparty_consent.options,
                        taggable: false,
                        multiple: false,
                        closeOnSelect: true,
                        rules: _vm.rules.thirdparty_consent,
                      },
                      model: {
                        value: _vm.inputVal.thirdparty_consent,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "thirdparty_consent", $$v)
                        },
                        expression: "inputVal.thirdparty_consent",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }