<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-show="!showForm">
          <validation-observer ref="observer1" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(proceed)">
              <b-card header="true" header-tag="header">
                <div
                  slot="header"
                  class="d-flex justify-content-between align-items-center"
                >
                  <p class="mb-0 d-inline-block align-middle">
                    <b>{{ "Seleziona".toUpperCase() }}</b>
                  </p>
                </div>
                <div>
                  <base-radio
                    :name="beForm[rep].status_registry.label"
                    vid="status_registry"
                    :label="getDictionary('status_registry', rep)"
                    v-model="form[rep].status_registry"
                    :options="beForm[rep].status_registry.options"
                    :rules="getRules('status_registry')"
                  />
                  <b-row v-if="form[rep].status_registry !== null">
                    <div class="col-sm-6">
                      <base-input
                        v-if="form[rep].status_registry === 0"
                        vid="attribute_NINO_value"
                        :name="beForm[rep].attribute_NINO_value.label"
                        :label="getDictionary('attribute_NINO_value', rep)"
                        v-model="form[rep].attribute_NINO_value"
                        placeholder="Inserisci un codice fiscale"
                        :rules="getRules('attribute_NINO_value')"
                        textcase="upper"
                      />
                      <base-input
                        v-else
                        vid="attribute_CREG_value"
                        :name="beForm[rep].attribute_CREG_value.label"
                        :label="getDictionary('attribute_CREG_value', rep)"
                        v-model="form[rep].attribute_CREG_value"
                        placeholder="Inserisci una partita IVA"
                        :rules="getRules('attribute_CREG_value')"
                      />
                      <b-button
                        variant="lisaweb"
                        type="submit"
                        :disabled="isValidating"
                        ><b-spinner
                          small
                          type="grow"
                          v-show="isValidating"
                        ></b-spinner
                        >Procedi</b-button
                      >
                    </div>
                  </b-row>
                </div>
              </b-card>
            </b-form>
          </validation-observer>
        </div>
        <div v-show="showForm">
          <validation-observer
            ref="observer2"
            v-slot="{ handleSubmit, invalid }"
          >
            <div style="display: none">
              <!-- devo mettere questo div nascosto per via della rule required_if, poichè status_registry sta in observer1 -->
              <base-input
                vid="status_registry"
                v-model="form[rep].status_registry"
              />
            </div>
            <div class="mt-2">
              <b-card header="Dati identificativi" header-tag="header">
                <b-card-text v-if="form[rep].status_registry === 0">
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="attribute_SURN_value"
                        :name="beForm[rep].attribute_SURN_value.label"
                        :label="getDictionary('attribute_SURN_value', rep)"
                        v-model="form[rep].attribute_SURN_value"
                        placeholder="Inserisci un cognome"
                        :rules="getRules('attribute_SURN_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="attribute_NAME_value"
                        :name="beForm[rep].attribute_NAME_value.label"
                        :label="getDictionary('attribute_NAME_value', rep)"
                        v-model="form[rep].attribute_NAME_value"
                        placeholder="Inserisci un nome"
                        :rules="getRules('attribute_NAME_value')"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <base-select
                        vid="attribute_TITL_value"
                        :name="beForm[rep].attribute_TITL_value.label"
                        :label="getDictionary('attribute_TITL_value', rep)"
                        v-model="form[rep].attribute_TITL_value"
                        :options="beForm[rep].attribute_TITL_value.options"
                        placeholder="Inserisci un titolo"
                        :rules="getRules('attribute_TITL_value')"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <base-radio
                        vid="attribute_SEX_value"
                        :name="beForm[rep].attribute_SEX_value.label"
                        :label="getDictionary('attribute_SEX_value', rep)"
                        v-model="form[rep].attribute_SEX_value"
                        :options="beForm[rep].attribute_SEX_value.options"
                        :rules="getRules('attribute_SEX_value')"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <base-input
                        vid="attribute_NINO_value"
                        :name="beForm[rep].attribute_NINO_value.label"
                        :label="getDictionary('attribute_NINO_value', rep)"
                        v-model="form[rep].attribute_NINO_value"
                        :rules="getRules('attribute_NINO_value')"
                        disabled
                        textcase="upper"
                      />
                    </div>
                  </b-row>
                  Nato a:
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_NOB_value"
                        :name="form[rep].attribute_NOB_value.label"
                        :label="getDictionary('attribute_NOB_value', rep)"
                        v-model="form[rep].attribute_NOB_value"
                        :options="form.optBornCountries"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        @select="onSelectBornCountry"
                      />
                    </div>
                    <!-- se provinceId (form.born_province) risulta null, significa che o il comune è soppresso o la provincia non esiste più
                  perciò non mostro la tendina delle province -->
                    <div
                      class="form-group col-md-3"
                      v-if="!isBornForeign && form.born_province"
                    >
                      <base-select
                        name="born_province"
                        vid="born_province"
                        label="Provincia"
                        v-model="form.born_province"
                        :options="form.optBornProvinces"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        @select="onSelectBornProvince"
                      />
                    </div>
                    <div class="form-group col-md-3" v-if="!isBornForeign">
                      <base-select
                        vid="attribute_POB_value"
                        :name="beForm[rep].attribute_POB_value.label"
                        :label="getDictionary('attribute_POB_value', rep)"
                        v-model="form[rep].attribute_POB_value"
                        :rules="getRules('attribute_POB_value')"
                        :options="form.optBornCouncils"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                      />
                    </div>
                    <div class="form-group col-md-3" v-else>
                      <base-input
                        vid="attribute_POB2_value"
                        :name="beForm[rep].attribute_POB2_value.label"
                        :label="getDictionary('attribute_POB2_value', rep)"
                        v-model="form[rep].attribute_POB2_value"
                        :rules="getRules('attribute_POB2_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="attribute_DOB_value"
                        :name="beForm[rep].attribute_DOB_value.label"
                        :label="getDictionary('attribute_DOB_value', rep)"
                        v-model="form[rep].attribute_DOB_value"
                        :rules="getRules('attribute_DOB_value')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
                <b-card-text v-else>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="attribute_CNAM_value"
                        :name="beForm[rep].attribute_CNAM_value.label"
                        :label="getDictionary('attribute_CNAM_value', rep)"
                        v-model="form[rep].attribute_CNAM_value"
                        placeholder="Inserisci una ragione sociale"
                        :rules="getRules('attribute_CNAM_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="attribute_CREG_value"
                        :name="beForm[rep].attribute_CREG_value.label"
                        :label="getDictionary('attribute_CREG_value', rep)"
                        v-model="form[rep].attribute_CREG_value"
                        :rules="getRules('attribute_CREG_value')"
                        disabled
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="attribute_NINO_value"
                        :name="beForm[rep].attribute_NINO_value.label"
                        :label="getDictionary('attribute_NINO_value', rep)"
                        v-model="form[rep].attribute_NINO_value"
                        :rules="getRules('attribute_NINO_value')"
                      />
                    </div>
                    <!-- attribute_LIAB_value -->
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_LIAB_value"
                        :name="beForm[rep].attribute_LIAB_value.label"
                        :label="getDictionary('attribute_LIAB_value', rep)"
                        v-model="form[rep].attribute_LIAB_value"
                        :rules="getRules('attribute_LIAB_value')"
                        :options="beForm[rep].attribute_LIAB_value.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
            </div>
            <div class="mt-1" v-if="origin?.routeName != 'module.PEASY.PRIV'">
              <address-book-form
                v-model="address_book_form"
                :beECForm="beForm[addrBook]"
                :repository="addrBook"
              ></address-book-form>
            </div>
            <div class="mt-1" v-if="origin?.routeName != 'module.PEASY.PRIV'">
              <correspondence-form
                v-model="correspondence_form"
                :beECForm="beForm[cor]"
                :repository="cor"
                :dynamicRules="false"
                :rules="{
                  attribute_NOR_value: getRules(
                    'attribute_NOR_value',
                    'correspondence'
                  ),
                  attribute_CITY_value: getRules(
                    'attribute_CITY_value',
                    'correspondence'
                  ),
                  attribute_CITY2_value: getRules(
                    'attribute_CITY2_value',
                    'correspondence'
                  ),
                  home_province: { required: true },
                  attribute_ZIPC_value: {},
                  attribute_ADDR_value: getRules(
                    'attribute_ADDR_value',
                    'correspondence'
                  ),
                  attribute_NAME2_value: {},
                }"
              >
              </correspondence-form>
            </div>
            <div class="mt-1" v-if="origin?.routeName === 'module.PEASY.PRIV'">
              <data-protection-form
                v-model="data_protection_form"
                :beECForm="beForm[dtPrt]"
                :repository="dtPrt"
                :rules="{
                  intermediary_consent: getRules(
                    'intermediary_consent',
                    'data_protection'
                  ),
                  traditional_consent: getRules(
                    'traditional_consent',
                    'data_protection'
                  ),
                  electronic_consent: getRules(
                    'electronic_consent',
                    'data_protection'
                  ),
                  thirdparty_consent: getRules(
                    'thirdparty_consent',
                    'data_protection'
                  ),
                }"
              >
              </data-protection-form>
            </div>
            <div class="mt-2" v-show="origin?.routeName != 'module.PEASY.PRIV'">
              <b-card
                v-if="form[rep].status_registry === 1"
                header="Altri Dati"
                header-tag="header"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="attribute_PROF_value"
                        :name="beForm[rep].attribute_PROF_value.label"
                        label="Attività"
                        v-model="form[rep].attribute_PROF_value"
                        placeholder="Inserisci il numero di documento"
                        :rules="getRules('attribute_PROF_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_CHAN_value"
                        :name="beForm[rep].attribute_CHAN_value.label"
                        label="Canale Preferito"
                        v-model="form[rep].attribute_CHAN_value"
                        :options="beForm[rep].attribute_CHAN_value.options"
                        :rules="getRules('attribute_CHAN_value')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
            </div>
            <div class="mt-2" v-show="origin?.routeName != 'module.PEASY.PRIV'">
              <b-card
                v-if="form[rep].status_registry === 0"
                header="Documento"
                header-tag="header"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-radio
                        :name="beForm[rep].attribute_DOCT_value.label"
                        vid="attribute_DOCT_value"
                        :label="getDictionary('attribute_DOCT_value', rep)"
                        v-model="form[rep].attribute_DOCT_value"
                        :options="beForm[rep].attribute_DOCT_value.options"
                        :rules="getRules('attribute_DOCT_value')"
                        stacked
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="attribute_DOCN_value"
                        :name="beForm[rep].attribute_DOCN_value.label"
                        :label="getDictionary('attribute_DOCN_value', rep)"
                        v-model="form[rep].attribute_DOCN_value"
                        placeholder="Inserisci il numero di documento"
                        :rules="getRules('attribute_DOCN_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_ISPL_value"
                        :name="beForm[rep].attribute_ISPL_value.label"
                        :label="getDictionary('attribute_ISPL_value', rep)"
                        v-model="form[rep].attribute_ISPL_value"
                        placeholder="Digitare le lettere iniziali..."
                        :options="ajaxOptions"
                        @search-change="getAjaxOptions"
                        :isLoading="isLoadingAjax"
                        :rules="getRules('attribute_ISPL_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="attribute_DOCS_value"
                        :name="beForm[rep].attribute_DOCS_value.label"
                        :label="getDictionary('attribute_DOCS_value', rep)"
                        v-model="form[rep].attribute_DOCS_value"
                        :rules="getRules('attribute_DOCS_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="attribute_DOCX_value"
                        :name="beForm[rep].attribute_DOCX_value.label"
                        :label="getDictionary('attribute_DOCX_value', rep)"
                        v-model="form[rep].attribute_DOCX_value"
                        :rules="getRules('attribute_DOCX_value')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
            </div>
            <div class="mt-2" v-show="origin?.routeName != 'module.PEASY.PRIV'">
              <b-card
                v-if="form[rep].status_registry === 0"
                header="Altro"
                header-tag="header"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-radio
                        vid="attribute_MARS_value"
                        :name="beForm[rep].attribute_MARS_value.label"
                        :label="getDictionary('attribute_MARS_value', rep)"
                        v-model="form[rep].attribute_MARS_value"
                        :options="beForm[rep].attribute_MARS_value.options"
                        :rules="getRules('attribute_MARS_value')"
                        stacked
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="attribute_PROF_value"
                        :name="beForm[rep].attribute_PROF_value.label"
                        :label="getDictionary('attribute_PROF_value', rep)"
                        v-model="form[rep].attribute_PROF_value"
                        placeholder="Inserisci una professione"
                        :rules="getRules('attribute_PROF_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_CHAN_value"
                        :name="beForm[rep].attribute_CHAN_value.label"
                        :label="getDictionary('attribute_CHAN_value', rep)"
                        v-model="form[rep].attribute_CHAN_value"
                        :options="beForm[rep].attribute_CHAN_value.options"
                        :rules="getRules('attribute_CHAN_value')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
            </div>
            <div
              class="mt-2"
              v-show="
                customInputs[rep].length &&
                origin?.routeName != 'module.PEASY.PRIV'
              "
            >
              <b-card header="Attributi personalizzati" header-tag="header">
                <b-card-text>
                  <b-row>
                    <custom-inputs
                      v-for="(element, index) in customInputs[rep]"
                      :key="index"
                      :input="element"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-row>
                </b-card-text>
              </b-card>
            </div>

            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button @click="resetRegistryForm()" variant="lisaweb" size="sm"
                >Reset</b-button
              >
              <b-button
                @click="$router.back()"
                variant="lisaweb"
                size="sm"
                class="float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </validation-observer>
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseRadio from "@/components/form/BaseRadio";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import AddressBookForm from "@/components/form/AddressBookForm";
import CorrespondenceForm from "@/components/form/CorrespondenceForm";
import DataProtectionForm from "@/components/form/DataProtectionForm";
import AddresBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import { mapActions, mapGetters } from "vuex";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomInputs from "@/components/form/CustomInputs";

export default {
  mixins: [
    FormMixin,
    ShortcutMixin,
    ConfirmModalMixin,
    AddresBookCorrespondenceMixin,
  ],
  name: "RegisterCustomer",
  data() {
    return {
      origin: this.$route.params.origin,
      repository: "registry",
      ajaxOptions: [],
      isLoadingAjax: false,
      isValidating: false,
      showForm: false,
      foreignCouncilName: "Comune estero",
      form: {
        defaultCountryName: "Italia",
        home_province: null,
        optBornCouncils: null,
        optBornCountries: null,
        optBornProvinces: null,
        optHomeCountries: [],
        optHomeProvinces: [],
        optHomeCouncils: [],
        born_province: null,
        born_city: null,
        registry: {
          status_registry: null,
          insurer_id: null,
          attribute_NOB_value: null,
          attribute_NINO_value: null,
          attribute_SURN_value: null,
          attribute_NAME_value: null,
          attribute_TITL_value: null,
          attribute_SEX_value: null,
          attribute_DOB_value: null,
          attribute_POB_value: null,
          attribute_POB2_value: null,
          attribute_CNAM_value: null,
          attribute_CREG_value: null,
          attribute_LIAB_value: null,
          attribute_DOCT_value: null,
          attribute_ISPL_value: null,
          attribute_DOCS_value: null,
          attribute_DOCX_value: null,
          attribute_MARS_value: null,
          attribute_PROF_value: null,
          attribute_WEB_value: null,
          attribute_CHAN_value: null,
          attribute_GDPR_value: null,
        },
        correspondence: {
          registry: {},
          status_correspondence: 0,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NAME2_value: null,
          attribute_NOR_value: null,
          attribute_ZIPC_value: null,
        },
        address_book: {
          status_addressbook: 0,
          attribute_CELL_value: null,
          attribute_FAX_value: null,
          attribute_MAIL_value: null,
          attribute_PEC_value: null,
          attribute_PHON_value: null,
          attribute_WEB_value: null,
          registry: {},
        },
        data_protection: {
          registry_id: null,
          intermediary_consent: "N",
          traditional_consent: "N",
          electronic_consent: "N",
          thirdparty_consent: "N",
        },
      },
    };
  },
  created() {
    this.isLoading = true;
    let promises = [];
    promises.push(this.fetchCreateForm(this.rep));
    promises.push(this.fetchCreateForm(this.pol));
    promises.push(this.fetchCreateForm(this.cor));
    promises.push(this.fetchCreateForm(this.addrBook));
    if (this.origin?.routeName === "module.PEASY.PRIV") {
      promises.push(this.fetchCreateForm(this.dtPrt));
    }
    Promise.all(promises).then(() => {
      this.isLoading = false;
    });
  },
  beforeMount() {
    this.initCreateCorrespondencesForm();
    this.form.optBornCountries = this.countries();
    this.form[this.rep].attribute_NOB_value = this.form.optBornCountries.find(
      (c) => c.text == this.form.defaultCountryName
    ).value;
    this.form.optBornProvinces = this.provinces()(
      this.form[this.rep].attribute_NOB_value
    );
    this.form.optBornCouncils = [];
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseRadio,
    BaseSelect,
    BaseDatepicker,
    AddressBookForm,
    CorrespondenceForm,
    DataProtectionForm,
    CustomInputs,
  },
  computed: {
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    pol() {
      return "insurance_policy";
    },
    addrBook() {
      return "address_book";
    },
    dtPrt() {
      return "data_protection";
    },
    isBornForeign() {
      if (!this.form[this.rep].attribute_NOB_value) return false;
      return (
        this.form.optBornCountries.find(
          (c) => c.value == this.form[this.rep].attribute_NOB_value
        ).text !== this.form.defaultCountryName
      );
    },
  },
  methods: {
    backToPrivEasy(id) {
      let name = this.origin.filterName;
      let criteria = {
        byAttribute: {
          id,
        },
      };
      // prima elimino il filtro, se c'è
      this.removeFilterByName(name);
      // poi lo salvo per scatenare l'autofetch
      this.saveFilterByName({ name, criteria });
      // shortcut(origin.routeName, 1, null, origin.filterName, { id: 1 })
      this.shortcut(this.origin.routeName);
    },
    resetRegistryForm() {
      const temp_form = JSON.parse(JSON.stringify(this.default_form));
      console.log("Form", temp_form);

      this.$set(this, "form", temp_form);
      //  this.$set(
      //     this.form[this.rep],
      //     "attribute_DOB_value",
      //     temp_form[this.rep].attribute_DOB_value
      //   );
    },
    getAjaxOptions(query) {
      if (query.length >= 2) {
        this.isLoadingAjax = true;
        this.ajaxOptions = this.councils()(null, query);
        this.isLoadingAjax = false;
      }
    },
    afterValidate(response, Repo, field, observer) {
      this.$refs[observer].refs[field].applyResult({
        errors: [], // array of string errors
        valid: true, // boolean state
        failedRules: {}, // should be empty since this is a manual error.
      });
      if (this.form[this.rep]["status_registry"] === 0) {
        let data = response.data.data[field];
        this.$set(
          this.form[this.rep],
          "attribute_SEX_value",
          this.beForm[this.rep]["attribute_SEX_value"].options.find(
            (a) => a.text == data.gender
          ).value
        );
        let country = this.$store.state.auth.countries.find(
          (c) => c.registry == data.birth_place
        );
        if (country) {
          this.$set(this.form[this.rep], "attribute_NOB_value", country.id);
          let council = this.councils()().find(
            (c) => c.text == this.foreignCouncilName
          );
          if (council) {
            this.$set(
              this.form[this.rep],
              "attribute_POB_value",
              council.value
            );
          }
        } else {
          // imposta provincia e luogo di nascita usando data.birth_place_complete
          // dato il council value, imposta la province
          const cc = Object.values(this.$store.state.auth.councils);
          const council = cc.find(
            (c) =>
              c.title.toLowerCase() === data.birth_place_complete.toLowerCase()
          );
          if (!council) {
            // GLVMRK84R30I459O
            // TODO: bisognerà capire cosa chiamare per ricevere i comuni "soppressi"...
            this.$showSnackbar({
              preset: "error",
              text: `Comune ${data.birth_place_complete} non trovato`,
            });
          } else {
            let cityId = council.id;
            let provinceId = council.province_id;
            // solo se provinceId not null
            if (provinceId) {
              this.$set(
                this.form,
                "optBornCouncils",
                this.councils()(
                  provinceId,
                  null,
                  true // include_dismissed
                )
              );
            } else {
              // altrimenti ci metto solo cityId (non posso mettere le oltre 9000 città)
              this.$set(
                this.form,
                "optBornCouncils",
                this.councils()(
                  null,
                  data.birth_place_complete, // council_partial_name
                  true // include_dismissed
                )
              );
            }
            this.form.born_province = provinceId;
            this.form.born_city = cityId;
            // attribute_POB_value
            this.$set(this.form[this.rep], "attribute_POB_value", cityId);
          }
        }
        // attribute_DOB_value
        this.$set(
          this.form[this.rep],
          "attribute_DOB_value",
          `${data.year}-${data.month}-${data.day}`
        );
      }

      let queryString = `byAttribute[${
        this.form[this.rep]["status_registry"] === 0 ? "NINO" : "CREG"
      }]=${this.form[this.rep][field]}`;

      let id;
      Repo.index(queryString).then((response) => {
        let data = response.data.data;
        if (data.length === 0) {
          // Now can show the form!
          this.showForm = true;
        } else if (data.length === 1) {
          id = data[0].id;
          // load details view
          this.showConfirm({
            yesCallback: () => {
              // this.$router.push({ name: "registries.edit", params: { id: `${id}` } });
              this.shortcut(
                "module.PFOLIO",
                null,
                "#Registries",
                "filterRegistry",
                { id: id },
                {
                  click: "openModal",
                  id: id,
                  modalName: "modal",
                  hash: "#General",
                }
              );
            },
            noCallback: null,
            title: "Conferma Azione",
            message: `${
              this.form[this.rep]["status_registry"] === 0
                ? "Codice Fiscale"
                : "Partita IVA"
            } già presente: visualizzare dettaglio?`,
            yesLabel: "VISUALIZZA",
            noLabel: "ANNULLA",
            // yesVariant:
            // noVariant:
            // headerBgVariant:
          });
          // load edit view
          // this.$router.push({ name: "registries.edit", params: { id: `${id}` } });
        } else {
          this.$showSnackbar({
            preset: "error",
            text: `${
              this.form[this.rep]["status_registry"] === 0
                ? "Codice Fiscale"
                : "Partita IVA"
            } presente ${data.length} volte: contattare l'assistenza.`,
          });
        }
      });
      // // Now can show the form!
      // this.showForm = true;
    },
    proceed() {
      // NOTE: make sure vid equals the field name, eg. vid = attribute_NINO_value
      let field =
        this.form[this.rep]["status_registry"] === 0
          ? "attribute_NINO_value"
          : "attribute_CREG_value";
      let observer = "observer1";
      const Repo = RepositoryFactory.get(this.repository);
      if (this.form[this.rep]["status_registry"] === 0) {
        // call parsecf specific validation for NINO
        let payload = {};
        payload[field] = this.form[this.rep][field];
        // this.validate(this.repository, field)
        this.isValidating = true;
        Repo.national_insurance(field, payload)
          .then((response) => {
            // try {
            //   this.afterValidate(response, Repo, field, observer);
            // } catch (error) {
            //   console.error(error.data.errors);
            //   let errMsg = this.$getErrorMessage(error);
            //   this.$showSnackbar({
            //     preset: "error",
            //     text: `${errMsg}`,
            //   });
            // }
            this.afterValidate(response, Repo, field, observer);
            this.default_form = JSON.parse(JSON.stringify(this.form));
          })
          .catch((error) => {
            this.$refs[observer].setErrors(error.data.errors);
            this.showForm = false;
            // let msg = `Campo ${field} non valido: ${error}. Correggi e riprova.`;
            // showSnackbar({ preset: "error", text: msg });
          })
          .finally(() => {
            this.isValidating = false;
          });
      } else {
        // call standard validation
        this.validate(this.repository, field)
          .then((response) => {
            this.afterValidate(response, Repo, field, observer);
            this.default_form = JSON.parse(JSON.stringify(this.form));
          })
          .catch((error) => {
            this.$refs[observer].setErrors(error.data.errors);
            this.showForm = false;
            // let msg = `Campo ${field} non valido: ${error}. Correggi e riprova.`;
            // showSnackbar({ preset: "error", text: msg });
          })
          .finally(() => {
            this.isValidating = false;
          });
      }
    },
    onSubmit() {
      // togliere
      this.isLoading = true;
      this.store(this.repository)
        .then((response) => {
          let registry_id = response.data.data.id;
          this.GDPR = response.data.data.attributables.GDPR;
          let promises = [];
          if (this.origin?.routeName != "module.PEASY.PRIV") {
            promises.push(
              this.storeSection(this.addrBook, registry_id, this.repository)
            );
            promises.push(
              this.storeSection(this.cor, registry_id, this.repository)
            );
            //Salva gli stessi dati della residenza sull'indirizzo di domicilio
            this.form[this.cor].status_correspondence = 1;
            promises.push(
              this.storeSection(this.cor, registry_id, this.repository)
            );
          } else {
            promises.push(
              this.storeSection(this.dtPrt, registry_id /* , this.repository */)
            );
          }

          Promise.all(promises)
            .then(() => {
              this.onSucess(registry_id);
            })
            .catch((error) => {
              this.onFailure(error);
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    ...mapGetters("auth", ["countries", "provinces", "councils"]),
    ...mapActions("filters", {
      removeFilterByName: "removeByName",
    }),
    onSucess(id) {
      if (this.origin?.routeName != "module.PEASY.PRIV") {
        this.showConfirm({
          yesCallback: () => {
            this.removeFilterByName("filterRegistry");
            this.shortcut("module.PFOLIO", null, "#Registries", null, null, {
              click: "openModal",
              id: id,
              modalName: "modal",
              hash: "#General",
              fromShortcut: true,
            });
          },
          noCallback: () => {
            this.shortcut(
              "module.PFOLIO",
              null,

              "#Registries",
              "filterRegistry"
            );
          },
          title: "Anagrafica creata",
          message: "Visualizzare il dettaglio?",
          yesLabel: "VISUALIZZA",
          noLabel: "ANNULLA",
        });
      } else {
        // torna alla index filtrata per id
        this.backToPrivEasy(id);
      }
      this.isLoading = false;
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    onSelectBornCountry(selected) {
      this.form.optBornProvinces = this.provinces()(selected);
      this.form.optBornCouncils = [];
      // this.form.born_province = null;
      // se Italia, rimetto provinceId e cityId presi dalla validazione CF
      if (
        selected ==
        this.form.optBornCountries.find(
          (c) => c.text == this.form.defaultCountryName
        ).value
      ) {
        this.onSelectBornProvince(this.form.born_province);
        this.$set(
          this.form[this.rep],
          "attribute_POB_value",
          this.form.born_city
        );
      } else {
        // this.form[this.rep].attribute_POB_value = null;
        this.$set(this.form[this.rep], "attribute_POB_value", null);
      }
    },
    onSelectBornProvince(selected) {
      this.form.optBornCouncils = [];
      this.form[this.repository].attribute_POB_value = null;
      // include is_dismissed
      this.form.optBornCouncils = this.councils()(selected, null, true);
    },
  },
};
</script>
